import React from "react";
import { makeStyles, Box, Typography, Button, Grid } from "@material-ui/core";
import { sortAddress } from "src/utils";
import { SUPPORTED_WALLETS } from "src/connectors";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "10px 0px 50px 0px",
    "& .mainBox": {
      "& h3": {
        fontSize: "25px",
        color: "#383838",
        textAlign: "center",
        [theme.breakpoints.only("xs")]: {
          fontSize: "18px",
          lineHeight: "30px",
        },
      },
      "& h4": {
        fontSize: "16px",
        fontWeight: "400",
        paddingTop: "10px",
      },
    },
  },

  learnMoreButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "& Button": {
      color: "#383838",
      display: "flex",
      alignItems: "center",
    },
    "& a": {
      fontSize: "16px",
      color: "#383838",
      fontWeight: "500",

      [theme.breakpoints.only("xs")]: {
        fontSize: "15px",
      },
    },
  },
  metamaskbox: {
    display: "flex",
    alignItems: "center",
    padding: "8px 30px 8px 30px",
    maxWidth: "350px",
    border: "1px solid #383838",

    "& h3": {
      fontSize: "22px",
      fontWeight: "500",
      color: "#383838",
      paddingLeft: "30px",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "3px",
      },
    },
  },
}));

function MetaMaskpopup({ user, account }) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Box align="center" mt={2} mb={2} className="mainBox">
        <Typography variant="h3">You need an account to play</Typography>
        <Typography variant="h4">Connect or setup an account with</Typography>
      </Box>

      <Box className="metamaskButton" align="center" mt={4}>
        {/* <Button
          onClick={() => {
            if (!account) {
              user.connectWallet();
            } else {
              user.disconnectWallet();
            }
          }}
          className={classes.metamaskbox}
        >
          {!account ? (
            <>
              <img src="images/mask.png" style={{ width: "51px" }} alt="" />
              <Typography variant="h3">Metamask</Typography>
            </>
          ) : (
            `Disconnect ${sortAddress(account)}`
          )}
        </Button> */}
        {!account ? (
          <Grid container spacing={3}>
            {SUPPORTED_WALLETS.map((item, i) => {
              return (
                <Grid xs={12} sm={12} key={i}>
                  <Box
                    mt={2}
                    // display="flex"
                    style={{ paddingTop: "10px", width: "100%" }}
                  >
                    <Button
                      fullWidth
                      onClick={() => {
                        window.sessionStorage.removeItem("walletName");
                        window.sessionStorage.setItem("walletName", item.name);
                        user.connectWallet(item.data);
                      }}
                      className={classes.metamaskbox}
                    >
                      <img
                        src={item.data.iconName}
                        style={{ width: "51px" }}
                        alt=""
                      />
                      <Typography variant="h3"> {item.data.name}</Typography>
                    </Button>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        ) : (
          <Button
            onClick={() => {
              if (!account) {
                user.connectWallet();
              } else {
                user.disconnectWallet();
              }
            }}
            className={classes.metamaskbox}
          >
            {`Disconnect ${sortAddress(account)}`}
          </Button>
        )}
      </Box>

      {/* <Box className={classes.learnMoreButtons} align="center" mt={3}>
        <Button
          target="_blank"
          // href="https://www.youtube.com/playlist?list=PL37ZVnwpeshFqN5dcZ704lxI3F5iHDYkl"
        >
          Learn More <ChevronRightIcon style={{ color: "#383838" }} />
        </Button>
      </Box> */}
    </Box>
  );
}

export default MetaMaskpopup;

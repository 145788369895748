import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
// import SocialBar from '../HomeLayout/SocialBar'
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100%",
    overflow: "hidden",
    width: "100%",
  },
  wrapper: {
    display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    paddingTop: 70,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    // display: "flex",
    flex: "1 1 auto",
    overflow: "hidden",
    background: theme.palette.background.dark1,
  },
  content: {
    flex: "1 1 auto",

    overflow: "hidden",
    position: "absolute",
    left: "auto",
    right: "-10%",
    top: "50%",

    transform: "translateY(-50%)",
    zIndex: " -1",
    width: " 900px",
    borderRadius: "6000px",
    backgroundImage:
      "radial-gradient(circle, rgba(0,212,255,1) 33%, rgba(115,211,18,1) 43%)",
    opacity: "0.55",
    filter: "blur(200px)",
    WebkitOverflowScrolling: "touch",
    padding: "10px 50px 0px ",
    minHeight: "91vh",
    [theme.breakpoints.down("md")]: {
      padding: "10px 20px 0px ",
      flex: "1 1 auto",

      overflow: "hidden",
      position: "absolute",
      left: "auto",
      right: "0%",
      top: "50%",

      transform: "translateY(-50%)",
      zIndex: " -1",
      width: " 900px",
      borderRadius: "6000px",
      backgroundImage:
        "radial-gradient(circle, rgba(0,212,255,1) 33%, rgba(115,211,18,1) 43%)",
      opacity: "0.55",
      filter: "blur(200px)",
      WebkitOverflowScrolling: "touch",
      // padding: "10px 50px 0px ",
      minHeight: "91vh",
    },
  },
}));

const DashboardLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div id="main-scroll">{children}</div>
          <div className={classes.content}></div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;

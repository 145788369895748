import React, { createContext, useEffect, useState } from "react";
import { SUPPORTED_WALLETS } from "src/connectors";
import { useWeb3React } from "@web3-react/core";
import {
  ACTIVE_NETWORK,
  NetworkDetails,
  generativeNFTAddress,
} from "src/constants";
import { toast } from "react-toastify";
import { dataPostHandler, getDataHandlerAPI } from "src/apiConfig/service";
import { getWeb3ContractObject } from "src/utils";
import Web3 from "web3";
import generativeNFTABI from "src/abi/generativeNFTABI.json";

export const UserContext = createContext();

const setSession = (userAddress) => {
  if (userAddress) {
    sessionStorage.setItem("userAddress", userAddress);
  } else {
    sessionStorage.removeItem("userAddress");
  }
};
function checkLogin() {
  const userType = window.sessionStorage.getItem("userType");
  return userType === "ADMIN" ? true : false;
}

export default function AuthProvider(props) {
  const { activate, account, chainId, deactivate, library } = useWeb3React();
  const [openmetamask, setOpenMetaMask] = useState(false);
  const [yourWalletBalance, setYourWalletBalance] = useState("");
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [collection, setCollection] = useState([]);
  const [socialData, setSocialData] = useState([]);
  const [isLoginAdmin, setIsLoginAdmin] = useState(checkLogin());
  const [contractDetails, setContractDetails] = useState({
    owner: "",
  });
  //wallet connection setup code (Metamask)
  const connectToWallet = (data) => {
    // const connector = injected;
    // if (connector && connector.walletConnectProvider?.wc?.uri) {
    //   connector.walletConnectProvider = undefined;
    // }
    // activate(connector, undefined, true)
    //   .then(() => {
    //     setOpenMetaMask(false);
    //   })
    //   .catch((error) => {
    //     if (error) {
    //       console.log("error", error.message);
    //       // toast.error(error.message);
    //       // activate(connector);
    //     }
    //   });
    if (data) {
      try {
        window.sessionStorage.removeItem("walletName");
        window.sessionStorage.setItem("walletName", "METAMASK");
        const connector = data.connector;

        if (connector && connector.walletConnectProvider?.wc?.uri) {
          connector.walletConnectProvider = undefined;
        }

        activate(connector, undefined, true).catch((error) => {
          if (error) {
            window.sessionStorage.removeItem("walletName");
            toast.error(JSON.stringify(error.message));
            window.sessionStorage.removeItem("walletName");
            activate(connector);
          }
        });
      } catch (error) {
        toast.error(JSON.stringify(error.message));
      }
    }
  };
  const swichNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x" + ACTIVE_NETWORK.toString(16) }],
      });
    } catch (error) {
      // toast.warn(error.message);
      if (error.code === 4902) {
        addNetworkHandler();
      }
    }
  };
  const addNetworkHandler = async () => {
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: NetworkDetails,
      });
    } catch (error) {
      console.log("ERROR", error);
      // toast.warn(error.message);
    }
  };
  const disconnectWalletHandler = async () => {
    try {
      deactivate();
      setOpenMetaMask(false);
      toast.success("You have been disconnected successfully!");
      window.sessionStorage.removeItem("token");
      window.sessionStorage.removeItem("userType");
      window.sessionStorage.removeItem("walletName");
      window.location = "/";
    } catch (error) {
      console.log(error);
    }
  };

  const connectWalletAPIHandler = async () => {
    try {
      const dataToSend = {
        walletAddress: account,
      };
      const response = await dataPostHandler("connectWallet", dataToSend);
      if (response) {
        const token = response.data.data.token;
        const userType = response.data.data.userType;
        window.sessionStorage.setItem("token", token);
        window.sessionStorage.setItem("userType", userType);
        getProfileDataHandler(token);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getProfileDataHandler = async (token) => {
    setIsLoading(true);
    try {
      const response = await getDataHandlerAPI("getProfile", token);
      if (response) {
        setUserData(response);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const getUserbalce = async () => {
    setYourWalletBalance("");
    var web3 = new Web3(library.provider);
    const balance = await web3.eth.getBalance(account);
    const balanceImETH = await web3.utils.fromWei(balance);
    setYourWalletBalance(parseFloat(balanceImETH).toFixed(2));
  };

  const staticContentList = async () => {
    setIsLoading(true);
    try {
      const res = await getDataHandlerAPI("staticContentList");
      if (res) {
        setCollection(res);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  const getContractDetailsHandler = async () => {
    try {
      const contract = getWeb3ContractObject(
        generativeNFTABI,
        generativeNFTAddress
      );
      const ownerFun = await contract.methods.owner().call();
      setContractDetails({
        owner: ownerFun.toString(),
      });
    } catch (error) {
      console.log(error);
    }
  };
  //SocialLink
  const SocialLinkListHandler = async () => {
    try {
      const response = await getDataHandlerAPI(
        "socialLinks",
        window.sessionStorage.getItem("token")
      );
      if (response) {
        setSocialData(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    staticContentList();
    SocialLinkListHandler();
    getContractDetailsHandler();
  }, [window.sessionStorage.getItem("token")]); // eslint-disable-line

  //context-data-layer-object
  let data = {
    isAdmin: isLoginAdmin,
    userData,
    openmetamask,
    collection,
    socialData,
    yourWalletBalance,
    isLoading,
    contractDetails,
    updateUser: (account) => {
      setSession(account);
    },
    connectWallet: (data) => connectToWallet(data),
    setOpenMetaMask: (data) => setOpenMetaMask(data),
    getProfileDataHandler: (data) => getProfileDataHandler(data),
    disconnectWallet: () => disconnectWalletHandler(),
    checkLogin: () => checkLogin(),
    setIsLoginAdmin: (data) => setIsLoginAdmin(data),
  };

  useEffect(() => {
    if (account && chainId) {
      if (chainId !== ACTIVE_NETWORK) {
        if (window.ethereum) {
          swichNetworkHandler();
        }
      }
    }
  }, [chainId, account]); // eslint-disable-line

  useEffect(() => {
    if (account) {
      getUserbalce();
      connectWalletAPIHandler();
    }
  }, [account]); // eslint-disable-line

  useEffect(() => {
    const userAddress = window.sessionStorage.getItem("userAddress");
    if (userAddress) {
      data.connectWallet();
    }
  }, []); //eslint-disable-line
  useEffect(() => {
    data.updateUser(account);
  }, [account]); //eslint-disable-line

  useEffect(() => {
    if (sessionStorage.getItem("walletName")) {
      const selectectWalletDetails = SUPPORTED_WALLETS.filter((data) => {
        return (
          data?.data?.name?.toUpperCase() ==
          sessionStorage.getItem("walletName")
        );
      });
      if (selectectWalletDetails.length > 0) {
        connectToWallet(selectectWalletDetails[0].data);
      }
    }
  }, [sessionStorage.getItem("walletName")]);
  return (
    <UserContext.Provider value={data}>{props.children}</UserContext.Provider>
  );
}

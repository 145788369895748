import React, { useState, useEffect, useContext } from "react";
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Divider,
  IconButton,
  Link,
} from "@material-ui/core";
import { AiOutlineMedium } from "react-icons/ai";
import {
  FaInstagram,
  FaFacebookF,
  FaTelegramPlane,
  FaDiscord,
} from "react-icons/fa";
import { SiSinaweibo } from "react-icons/si";
import { Link as RouterLink } from "react-router-dom";
import { FiTwitter } from "react-icons/fi";
import Logo from "src/component/Logo";
import { useHistory } from "react-router-dom";
import { UserContext } from "src/context/User";
import { FiFacebook } from "react-icons/fi";
const useStyles = makeStyles((theme) => ({
  footerBox: {
    display: "flex",
    padding: "40px 0px 20px",
    position: "relative",
    background: "#ffffff",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    borderTop: "2px solid #41b2f3",
    "& .vectorImage": {
      position: "absolute",
      right: "9%",
      top: "5%",
      width: "100%",
      maxWidth: "250px",
    },
    "& .mainBox": {
      position: "relative",
      zIndex: "999",

      "& .IconsBox": {
        padding: "15px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      "& .footerLinkPages": {
        display: "flex",
        alignItems: "center",
        "& p": {
          color: "#383838",
          fontSize: "15px",
          fontWeight: "500",
        },
        justifyContent: "center",
        position: "relative",
        flexWrap: "wrap",
        paddingBottom: "30px",
        borderBottom: "1px solid #3232324d",
        "& h4": {
          padding: "15px 0px",
          fontSize: "12px",
          display: "flex",
          alignItems: "center",
          "& span": {
            cursor: "pointer",
            display: "flex",
            width: "max-content",
          },
        },
      },
    },
  },
  socialgrid: {
    display: "flex",
    padding: "15px 0px",
    alignItems: "center",
    justifyContent: "center",
    "& svg": {
      fontSize: "23px",
      color: "#41b1f3",
    },
    "& .nonHover": {
      "& .img1": {
        display: "block",
        marginBottom: "5px",
        marginLeft: "10px",
        width: "21px",
      },
      "& .img2": {
        display: "none",
        marginBottom: "5px",
        marginLeft: "10px",
        width: "21px",
      },
      "&:hover": {
        "& .img1": {
          display: "none",
        },
        "& .img2": {
          display: "block",
        },
      },
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  const history = useHistory();
  const user = useContext(UserContext);
  return (
    <>
      <Box className={classes.footerBox}>
        <Container maxWidth="lg">
          <Box className="mainBox">
            <Box align="center">
              <RouterLink to="/">
                <Logo className="logoImg" />
              </RouterLink>
            </Box>
            <Box className={classes.socialgrid}>
              <Link href={user?.socialData[0]?.link} target="_blank">
                <IconButton>
                  <FiFacebook />
                </IconButton>
              </Link>

              <Link href={user?.socialData[3]?.link} target="_blank">
                <IconButton>
                  <FaTelegramPlane />
                </IconButton>
              </Link>
              <Link href={user?.socialData[2]?.link} target="_blank">
                <IconButton>
                  <FaInstagram />
                </IconButton>
              </Link>

              <Link href={user?.socialData[1]?.link} target="_blank">
                <IconButton>
                  <FiTwitter />
                </IconButton>
              </Link>
              <Link
                href="https://discord.com/invite/9HCNTyqkwa"
                target="_blank"
              >
                <IconButton>
                  {" "}
                  <FaDiscord />
                </IconButton>
              </Link>
              <Link href="https://weibo.com/pawsome" target="_blank">
                <IconButton>
                  <SiSinaweibo />
                  {/* <Box className="nonHover">
                    <img src="images/aa.png" alt="" className="img1" />{" "}
                    <img src="images/aa-1.png" alt="" className="img2" />
                  </Box> */}
                </IconButton>
              </Link>
            </Box>
            <Box className="footerLinkPages">
              {/* <Typography variant="h4">
                <span onClick={() => history.push("/about")}>About</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span onClick={() => history.push("/media")}>Media</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span >Privacy</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span >
                  Terms and Conditions
                </span>
              </Typography> */}
              <Box mx={2} style={{ cursor: "pointer" }}>
                <Typography
                  variant="body1"
                  onClick={() => history.push("/about-us")}
                >
                  About Us
                </Typography>
              </Box>

              <Box mx={2} style={{ cursor: "pointer" }}>
                <Typography
                  variant="body1"
                  onClick={() => history.push("/privacy")}
                >
                  Privacy Policy
                </Typography>
              </Box>
              <Box mx={2} style={{ cursor: "pointer" }}>
                <Typography
                  variant="body1"
                  onClick={() => history.push("/terms")}
                >
                  {" "}
                  Terms And Conditions
                </Typography>
              </Box>
            </Box>

            <Box align="center" mt={2}>
              <Typography
                variant="body1"
                style={{
                  color: "rgb(66 63 63)",
                  fontSize: "13px",
                  fontWeight: "400",
                }}
              >
                Copyright © 2022-23 PAWSOME, ALL RIGHTS RESERVED
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}
